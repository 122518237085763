import { useMemo, useState } from "react";
import { GridTile } from "./GridTable";

type GridFilterProps<T> = {
  tiles: T[];
  categories?: Record<string, string>;
};

export type GridFilter<T> = {
  filteredTiles: T[];
  textFilter: string;
  setTextFilter: (textFilter: string) => void;
  categoryFilter: number;
  setCategoryFilter: (categoryFilter: number) => void;
};

export function useGridFilter<T extends GridTile>(props: GridFilterProps<T>) {
  const { tiles, categories } = props;

  const [textFilter, setTextFilter] = useState<string>("");
  const [categoryFilter, setCategoryFilter] = useState<number>(0);

  const filteredTiles: T[] = useMemo(() => {
    let newTiles = tiles;
    if (categoryFilter !== 0 && categories) {
      newTiles = newTiles.filter(
        (tile: T) => tile.category === Object.keys(categories)[categoryFilter],
      );
    }

    if (textFilter !== "") {
      newTiles = newTiles.filter((tile: T) =>
        tile.name.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }

    return newTiles;
  }, [tiles, categories, textFilter, categoryFilter]);

  return {
    filteredTiles,
    textFilter,
    setTextFilter,
    categoryFilter,
    setCategoryFilter,
  };
}
