import { ReactNode } from "react";
import { Card, CardBody, CardText } from "reactstrap";
import { ButtonBase } from "@mui/material";
import classNames from "classnames";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import { CDataTypography } from "src/components/text/CDataTypography";
import "./SimpleTile.scss";

export type SimpleTileProps = {
  name: string;
  icon: JSX.Element;
  tooltip?: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  endAdornment?: JSX.Element;
};

export const SimpleTile = (props: SimpleTileProps) => {
  const {
    name,
    icon,
    tooltip,
    onClick,
    isSelected,
    isDisabled,
    isReadOnly,
    endAdornment,
  } = props;

  return (
    <CDataTooltip
      open={Boolean(tooltip)}
      type={CDataTooltipType.Dark}
      title={tooltip}
    >
      <div>
        <Card
          className={classNames("simple-tile", {
            "disabled-opacity": isDisabled,
            "is-selected": isSelected,
            "read-only": isReadOnly,
          })}
        >
          <ButtonBase
            disableRipple
            className="clickable-area"
            disabled={isDisabled || isReadOnly}
            onClick={() => {
              isDisabled ? null : onClick();
            }}
          >
            <div className="tile-icon-container">{icon}</div>
            <CardBody className="card-body">
              <CardText id={`row-${name}`} tag="div">
                <div>
                  <CDataTypography
                    variant="typography-variant-body-medium"
                    color="typography-color-text-muted"
                  >
                    {name}
                  </CDataTypography>
                </div>
              </CardText>
              {endAdornment && (
                <div className="align-content-center">{endAdornment}</div>
              )}
            </CardBody>
          </ButtonBase>
        </Card>
      </div>
    </CDataTooltip>
  );
};
