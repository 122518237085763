import classNames from "classnames";
import { isEmpty } from "lodash";
import { CDataTextField } from "../form/CDataTextField";
import "./CDSearch.scss";

export interface CDSearchProps {
  value: string | null | undefined;
  setValue: (value: string) => void;
  searchPlaceholder?: string;
  className?: string;
}

export const CDSearch = (props: CDSearchProps) => {
  const { searchPlaceholder, value, setValue, className } = props;

  return (
    <div className={classNames("cd-search", className)}>
      <i className="fa-regular fa-magnifying-glass align-middle me-2 search-icon" />
      <CDataTextField
        data-testid="table-search-bar"
        value={value ?? ""}
        autoFocus={false}
        onChange={(event) => setValue(event.target.value)}
        placeholder={searchPlaceholder ?? "Search..."}
        aria-label={searchPlaceholder ?? "Search"}
        className="w-100"
      />
      {!isEmpty(value) && (
        <div className="clear-container">
          <button
            className="table-button list-table-clear-button"
            onClick={(event) => {
              event.stopPropagation();
              setValue("");
            }}
          >
            <i className="fa fa-solid fa-circle-xmark" />
          </button>
        </div>
      )}
    </div>
  );
};
