import classnames from "classnames";
import { CDataRow } from "src/components/layout/CDataRow";
import { CDataCol } from "src/components/layout/CDataCol";
import { GridDropdown } from "./GridDropdown";
import { GridFilter } from "./useGridFilter";
import { CDSearch } from "../CDSearch";
import "./GridTable.scss";

export enum TileWidth {
  Short = "short",
  Long = "long",
}

export type GridTile = {
  key?: string;
  name: string;
  category?: string;
  tile: JSX.Element;
};

interface GridTableProps<T> {
  tiles: T[];
  tileWidth?: TileWidth;
  searchHidden?: boolean;
  categories?: Record<string, string>;
  emptyElement: JSX.Element;
  className?: string;
  gridFilter: GridFilter<T>;
  elementUnderSearch?: JSX.Element;
  scrollableContainerClass?: string;
}

export function GridTable<T extends GridTile>(props: GridTableProps<T>) {
  const {
    tiles,
    tileWidth,
    searchHidden,
    categories,
    emptyElement,
    className,
    gridFilter,
    elementUnderSearch,
    scrollableContainerClass,
  } = props;

  const {
    filteredTiles,
    textFilter,
    setTextFilter,
    categoryFilter,
    setCategoryFilter,
  } = gridFilter;

  return (
    <div className={classnames("cd-grid-table", className)}>
      <CDataRow hidden={searchHidden} noGutters className="mt-3">
        <CDataCol className={"flex-grow-1 pe-0"}>
          <CDSearch
            value={textFilter}
            setValue={setTextFilter}
            searchPlaceholder="Search by name..."
            className={classnames("flex-grow-1", {
              "no-categories": !categories,
            })}
          />
        </CDataCol>
        {categories && (
          <GridDropdown
            categories={categories}
            categoryFilter={categoryFilter}
            setCategoryFilter={setCategoryFilter}
            tiles={tiles}
          />
        )}
      </CDataRow>
      {elementUnderSearch}
      {filteredTiles.length > 0 && (
        <CDataRow
          noGutters
          className={classnames(
            "scrollable-container card-row",
            tileWidth,
            scrollableContainerClass,
          )}
        >
          {filteredTiles.map((card) => (
            <CDataCol key={card.key ?? card.name}>{card.tile}</CDataCol>
          ))}
        </CDataRow>
      )}
      {filteredTiles.length === 0 && emptyElement}
    </div>
  );
}
